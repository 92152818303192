<template>
    <v-tooltip
        right
        :max-width="$attrs['target-width'] || 300"
        class="ma-10"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-icon
                color="primary"
                dark
                class="ml-3"
                :class="$attrs['target-class']"
                size="16"
                v-bind="attrs"
                v-on="on"
            >
                fas fa-info
            </v-icon>
        </template>
        <span>
            <slot></slot>
        </span>
    </v-tooltip>
</template>

<script>
export default {
    name: "BaseTooltip",
    inheritAttrs: false,
};
</script>
