<template>
    <v-app-bar
        app
        flat
        dense
    >
        <v-app-bar-nav-icon
            class="nav-icon"
            @click="toggleDrawer()"
        ></v-app-bar-nav-icon>

        <v-spacer></v-spacer>

        <div>
            <v-btn
                depressed
                text
                dense
                small
                link
                class="btn-nav-items"
                color="primary"
                :to="{name: 'Notifications'}"
            >
                {{ $t(`navBar.notificationsBtn`) }}
                <v-badge
                    v-if="unReadCounter > 0"
                    color="error"
                    :content="unReadCounter"
                    offset-x="6"
                    offset-y="6"
                    bordered
                    transition="scale-rotate-transition"
                >
                    <v-icon
                        class="ml-2"
                        size="16"
                        color="error"
                    >
                        fa-solid fa-bell
                    </v-icon>
                </v-badge>
                <v-icon
                    v-else
                    class="ml-2"
                    size="16"
                >
                    fa-solid fa-bell
                </v-icon>
            </v-btn>
            <v-menu
                v-if="isLoggedIn"
                offset-x
                nudge-bottom="40"
                min-width="160"
                transition="slide-y-transition"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        depressed
                        text
                        dense
                        small
                        color="primary"
                        class="btn-nav-items"
                        v-bind="attrs"
                        v-on="on"
                    >
                        {{ userInfo.name }}
                        <v-icon class="ml-2" size="16">
                            fa-solid fa-angle-down
                        </v-icon>
                    </v-btn>
                </template>
                <v-card flat>
                    <v-card-text class="pa-1">
                        <v-list
                            dense
                            nav
                        >
                            <v-list-item
                                v-for="(item, index) in items"
                                :key="index"
                                color="primary"
                                link
                                :to="goTo(item)"
                            >
                                <v-list-item-icon>
                                    <v-icon
                                        size="18"
                                        color="primary"
                                        v-text="item.icon"
                                    ></v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="navbar-list-item">
                                        {{ $t(`navBar.${item.title}`) }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                            <v-divider class="my-1"></v-divider>
                            
                            <v-list-item
                                class="item-hover"
                                @click="handleLogout()"
                            >
                                <v-list-item-icon>
                                    <v-icon size="18" color="primary">
                                        fa-solid fa-arrow-right-from-bracket
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="navbar-list-item">
                                        {{ $t(`navBar.logOut`) }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-menu>
             <v-btn
                v-else
                depressed
                text
                dense
                small
                link
                color="primary"
                :to="{name: 'Login'}"
            >
                <span class="d-flex">
                    {{ $t(`navBar.logIn`) }}
                    <v-icon class="ml-2 align-self-end" size="16">
                        fa-solid fa-arrow-right-to-bracket
                    </v-icon>
                </span>
            </v-btn>
        </div>
    </v-app-bar>
</template>

<script>
import { withAsync } from "@/helpers/withAsync";
import { logOutUser } from "@/api/authApi.js";
import { apiStatus } from  "@/api/constants/apiStatus";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import { getNotifications } from "@/api/notificationApi.js";
import { apiObject } from "@/api/api";
import { mapGetters } from "vuex";
export default {
    name: "Navbar",

    data() {
        return {
            logOutStatus: apiStatus.Idle
        }
    },

    computed: {
        ...mapGetters({
            isOpenDrawer: "globals/getDrawer",
            userInfo: "auth/getUserInfo",
            isLoggedIn: "auth/isLoggedIn",
            userRole: "auth/getUserRole",
            unReadCounter: "globals/getNotificationCounter"
        }),
        ...apiStatusComputed("logOutStatus"),

        items() {
            return [
                {
                    title: "profile",
                    icon: "fa-solid fa-user-gear",
                    name: "Profile",
                    id: this.userInfo.userable_id
                },
                {
                    title: "export",
                    icon: "fa-solid fa-download",
                    name: "ExportRequests",
                }
            ]
        },
    },

    methods: {
        toggleDrawer() {
            let drawer = !this.isOpenDrawer
            this.$store.dispatch("globals/setDrawer", drawer);
        },

        async handleLogout() {
            this.logOutStatus = apiStatus.Pending;

            const { response, error } = await withAsync(logOutUser);

            if ( error ) {
                this.logOutStatus = apiStatus.Error;
                return;
            }

            this.$store.dispatch("auth/removeToken");
            this.$store.dispatch("auth/removeRole");
            this.$store.dispatch("auth/removeUserInfo");
            localStorage.removeItem("hma_edit_from")
            this.$router.push( { name: "Login" });
            this.logOutStatus = apiStatus.Success;
            
            delete apiObject.defaults.headers.common["Authorization"];
        },

        async handleNotifications() {
            this.handleNotificationsStatus = apiStatus.Pending;

            const params = {
                ...this.queryParams
            };

             Object.keys(params).forEach(key => {
                if (!params[key]) {
                    delete params[key];
                }
            });

            const { response, error } = await withAsync(getNotifications, params);

            if (error) {
                this.handleNotificationsStatus = apiStatus.Error;
                return;
            }
            this.$store.dispatch("globals/setNotificationCounter", response.data.new_notifications_count);
        },

        goTo(item) {
            if (item.id) {
                let name = this.roleBasedRedirection();
                return {
                    name,
                    params: {id: this.userInfo.userable_id}
                };
            }
            return {name: item.name};
        },

        roleBasedRedirection() {
            switch(this.userRole) {
                case "admin":
                case "mod":
                case "analyst":
                    return "Profile";
                case "company": 
                    return "Company";
                case "settlement":
                    return "Settlement";
                case "collector": 
                    return "Collector";
                default:
                    console.log("NO REDIRECTION");
                    break;
            }
        }
    },

    created() {
        this.apiStatus = apiStatus;
        this.handleNotifications();
    },
}
</script>

<style lang="scss" scoped>
.nav-icon {
    color: var(--v-primary-base) !important;
}
.toolbar-title {
    color: var(--v-primary-base);
    font-weight: 500;
    font-size: 1.4em;
}

.btn-nav-items {
    font-size: 0.84em;
    font-weight: 500;
    letter-spacing: 0.04em;
}

.navbar-list-item {
    font-size: 1em !important;
    font-weight: 500 !important;
    letter-spacing: 0.04em !important;
    color: var(--v-textDark-lighten1) !important;
}
</style>