const setDrawer = ( state, data ) => {
    state.drawer = data;
};

const setNotificationCounter = ( state, data ) => {
    state.notificationCounter = data;
};

const setCompanyUpdate = ( state, data ) => {
    state.wasCompanyUpdated = data;
};

export default {
    setDrawer,
    setNotificationCounter,
    setCompanyUpdate
}