<template>
    <v-navigation-drawer
        v-model="openDrawer"
        app
        dark
        color="sidenav"
    >
        <v-sheet
            color="sidenav"
            class="pa-2 d-flex justify-center"
        >
            <h2 class="nav-title">
                <a
                    href="https://home-wrm-new.draxis.gr/"
                    target="_blank"
                >
                    {{ $t(`navBar.navTitle`) }}
                </a>
            </h2>
        </v-sheet>

        <v-divider class="nav-divider"></v-divider>

        <v-list class="side__nav-list pt-0">
            <template v-for="(navItem, i) in navItems">
                <v-list-group
                    v-if="navItem.hasSubItems"
                    :key="`${i}-b`"
                    v-model="navItem.active"
                    class="side-menu-group"
                >
                    <template v-slot:prependIcon>
                        <v-icon class="color-icon" size="18">
                            {{ navItem.icon }}
                        </v-icon>
                    </template>
                    <template v-slot:appendIcon>
                        <v-icon class="color-icon" size="18">
                            fa-solid fa-angle-down
                        </v-icon>
                    </template>
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title class="text-wrap">
                                {{ $t(`sideBar.${navItem.label}`) }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </template>

                    <template v-for="(subItem, y) in navItem.subItems">
                        <v-list-group
                            v-if="subItem.hasInnerItems && canViewOption(subItem.canView)"
                            :key="`${y}-b`"
                            v-model="subItem.active"
                            class="inner-menu-group"
                            
                            sub-group
                        >
                            <template v-slot:prependIcon>
                                <v-icon size="18" class="mr-4 color-icon">
                                    {{ subItem.icon }}
                                </v-icon>
                            </template>
                            <template v-slot:appendIcon>
                                <v-icon class="color-icon" size="18">
                                    fa-solid fa-angle-down
                                </v-icon>
                            </template>
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ $t(`sideBar.subItems.${subItem.label}`) }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </template>

                            <v-list-item
                                v-for="(innerItem, k) in subItem.subSubItems"
                                :key="`${k}-c`"
                                :to="innerItem.to"
                                class="px-9"
                                link
                            >
                                <v-list-item-icon>
                                    <v-icon class="color-icon" small>
                                        {{ innerItem.icon }}
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="text-wrap">
                                        {{ $t(`sideBar.subItems.innerItems.${innerItem.label}`) }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-group>
                        <v-list-item
                            v-else-if="canViewOption(subItem.canView)"
                            :key="`${y}-b`"
                            :to="subItem.to"
                            class="px-6"
                            link
                        >
                            <v-list-item-icon>
                                <v-icon class="color-icon" small>
                                    {{ subItem.icon }}
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap sub-items">
                                    {{ $t(`sideBar.subItems.${subItem.label}`) }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list-group>

                <v-list-item
                    v-else
                    :key="`${i}-a`"
                    :to="navItem.to"
                    link
                >
                    <v-list-item-icon>
                        <v-icon class="color-icon" small>
                            {{ navItem.icon }}
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title class="text-wrap">
                            {{ $t(`sideBar.${navItem.label}`) }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
        <div class="developed_by d-flex justify-center">
            <a href="https://draxis.gr/" target="_blank">
                Developed by <br> <span>DRAXIS ENVIRONMENTAL S.A.</span>
            </a>
        </div>
    </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { getSideNavItems } from "@/helpers/sidenavItems.js";
export default {
    name: "Sidebar",

    data() {
        return {
            openDrawer: null,
            navItems: [],
        }
    },

    computed: {
        ...mapGetters({
            isOpenDrawer: "globals/getDrawer",
            userRole: "auth/getUserRole",
            userInfo: "auth/getUserInfo",
            wasCompanyUpdated: "globals/getCopmanyUpdate" 
        }),
    },

    methods: {
        handleSideNav() {
            this.navItems = getSideNavItems(this.userRole, this.userInfo);
        },

        isMobile() {
            if( /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                this.openDrawer = false;
                this.$store.dispatch("globals/setDrawer", false);
                return;
            }

            if (window.matchMedia("only screen and (max-width: 900px)").matches){
                this.openDrawer = false;
                this.$store.dispatch("globals/setDrawer", false);
                return
            }
            this.openDrawer = true;
            this.$store.dispatch("globals/setDrawer", true);
            return

        },

        canViewOption(whoCan) {
            let [roleOne, roleTwo = false, roleThree = false] = whoCan.map(item => item == this.userRole);
            let [all] = whoCan.map(item => item == "all");

            if (all) {
                return true
            }

            if (roleOne) {
                return true;
            }
            if (roleTwo) {
                return true
            }
            if (roleThree) {
                return true
            }
            return false
        },

    },

    watch: {
        isOpenDrawer() {
            this.openDrawer = this.isOpenDrawer;
        },

        openDrawer: {
            handler(val) {
                this.$store.dispatch("globals/setDrawer", val);
            }
        },

        wasCompanyUpdated: {
            handler(val) {
                if (val) this.handleSideNav();
            }
        }
    },

    created() {
        this.handleSideNav();
        this.isMobile()
    }
}
</script>

<style lang="scss" scoped>
.side-nav-items {
    text-transform: capitalize;
}
.sub-items {
    font-size: 0.86em;
}

.text-wrap-1 {
    color: red;
}

.nav-title {
    a {
        text-decoration: none;
        color: #fff;
        letter-spacing: 0.05em;
        line-height: 1.4;
    }
}

.nav-divider {
    border-color: #fff;
    border-width: 1px;
}

.developed_by {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 6%;
    padding: 0;
    a {
        background-color: #304156;
        padding: 0.1em 1.4em;
        font-size: 0.66em;
        font-weight: 400;
        letter-spacing: 0.04em;
        line-height: 1.3;
        color: #bfcbd9;
        text-decoration: none;

        span {
            font-weight: 500;
            font-size: 1em;
        }
    }
}

</style>