const getDrawer = state => state.drawer;

const getNotificationCounter = state => state.notificationCounter;

const getCopmanyUpdate = state => state.wasCompanyUpdated;

export default {
    getDrawer,
    getNotificationCounter,
    getCopmanyUpdate
}