const setDrawer = ({ commit }, payload) => {
    commit("setDrawer", payload);
};

const setNotificationCounter = ({ commit }, payload) => {
    localStorage.setItem("hma_notification_counter", payload );
    commit("setNotificationCounter", payload);
};

const setCompanyUpdate = ({ commit }, payload) => {
    commit("setCompanyUpdate", payload);
};

export default {
    setDrawer,
    setNotificationCounter,
    setCompanyUpdate
}