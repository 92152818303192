import axios from "axios";
import router from "../router";
import store from "../store";
import { CONFIG } from "../common/config";
import { MessageSuccess, MessageError } from "./servises/ToastNotification";
import i18n from "../plugins/i18n";

const AUTHORIZE = `${localStorage.getItem("hma_extention_token")}`;

let HEADERS = {
    "Content-Type": "application/json",
    Accept: "application/json",
};

if (AUTHORIZE != "null") {
    HEADERS["Authorization"] = AUTHORIZE;
}

const axiosParams = {
    baseURL: CONFIG.main_api,
    headers: HEADERS,
};

// Axios instance
const axiosInstance = axios.create(axiosParams);

// Error handling
const errorInterceptor = (error) => {
    let message;
    // check if it's a server error
    if (!error.response) {
        MessageError(i18n.t(`messages.error.error`));
        return Promise.reject(error);
    }

    // all the error responses
    switch (error.response.status) {
        case 401: // authentication error, logout the user
            router.push({ name: "Login" });
            break;
        case 403: // authentication error, logout the user
            router.push({ name: "UnAuthorized" });
            break;
        case 422:
            message = error.response.data.metadata?.response_message || error.response.data.message
            MessageError(message);
            break;
        case 429:
            router.replace({ name: "TooManyRequests" });
            break;
        case 500: // authentication error, logout the user
            // router.push({ name: "ServerError" });
            MessageError(error.response.data.message);
            break;
        default:
    }
    return Promise.reject(error);
};

// Success responses
const responseInterceptor = (response) => {
    if (response.config.showToast) {
        MessageSuccess(response.data.metadata.response_message);
    }
    return response;
};

// Interceptor response
axiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor);

// Main api function
const api = (axios) => {
    const logger = async (promise) =>
        promise.catch((error) => {
            if (process.env.NODE_ENV !== "development") throw error;

            if (error.response) {
                // Any errors
                console.log(error.response);
            } else if (error.request) {
                // No response at all
                console.log(error.request);
            } else {
                // Something else happened that triggered an error
                console.error("Error", error.message);
            }

            console.log(error.config);
            throw error;
        });

    return {
        get: (url, config) => logger(axios.get(url, config)),
        post: (url, body, config) => logger(axios.post(url, body, config)),
        put: (url, body, config) => logger(axios.put(url, body, config)),
        patch: (url, body, config) => logger(axios.patch(url, body, config)),
        delete: (url, config) => logger(axios.delete(url, config)),
    };
};

export default api(axiosInstance);
export const apiObject = axiosInstance;
