import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
    drawer: true,
    notificationCounter: localStorage.getItem("hma_notification_counter") || null,
    wasCompanyUpdated: false,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};