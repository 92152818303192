import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify/index";
import i18n from "./plugins/i18n";
import Vuelidate from "vuelidate"
import "@/plugins/dayjs";
import ProperDate from "./common/proper.date.filter";
import ShowDate from "./common/show.date.filter";

import "@fortawesome/fontawesome-free/css/all.css";

/**
* Globally register all `_base`-prefixed components
*/
import "./helpers/globals.js"

Vue.config.productionTip = false;

Vue.use(Vuelidate);

Vue.filter("properDate", ProperDate);
Vue.filter("ShowDate", ShowDate);

new Vue({
    name: "Hma",
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
