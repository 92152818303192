const setToken = ({ commit }, payload) => {
    localStorage.setItem("hma_extention_token", `Bearer ${ payload }`);
    commit("setToken", `Bearer ${ payload }`);
};

const removeToken = ( { commit } ) => {
    localStorage.removeItem("hma_extention_token");
    commit("removeToken");
};

const setUserRole = ( { commit }, payload ) => {
    localStorage.setItem("hma_user_role", payload );
    commit("setUserRole", payload)
};

const removeRole = ( { commit } ) => {
    localStorage.removeItem("hma_user_role");
    commit("removeRole");
};

const setUserInfo = ( { commit }, payload ) => {
    localStorage.setItem("hma_user_info", JSON.stringify(payload));
    commit("setUserInfo", payload);
};

const removeUserInfo = ( { commit } ) => {
    localStorage.removeItem("hma_user_info");
    commit("removeUserInfo");
};

export default {
    setToken,
    setUserRole,
    removeToken,
    removeRole,
    setUserInfo,
    removeUserInfo
}