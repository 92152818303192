<template>
    <v-container fluid class="login">
        <div class="overlay"></div>

        <div class="login__box shadow">
            <div class="login__header">
                <div class="mt-2 mb-4">
                    <h1 class="login-form-title">
                        {{ $t( `general.appTitle` ) }}
                    </h1>
                </div>
            </div>

            <div class="login__content">
                <base-info-alert color="primary">
                    {{ $t(`auth.login.info`) }}
                </base-info-alert>
            </div>
            
            <div class="login__form mt-4">
                <v-form ref="loginForm" :autocomplete="false" full-width>
                    <general-input
                        :input-value.sync="username"
                        :is-valid.sync="isUsernameValid"
                        :label="$t('auth.username')"
                    ></general-input>
                    <password-input
                        v-model="password"
                        :is-valid.sync="isPasswordValid"
                        :form-item="passwordItem"
                    ></password-input>
                    <div class="forgot-container">
                        <v-btn
                            text
                            depressed
                            color="primary"
                            link
                            :to="{name: 'Forgot'}"
                        >
                            {{ $t("auth.login.forgotButton") }}
                        </v-btn>
                    </div>

                    <login-button
                        :is-valid="isValid"
                        :username="username"
                        :password="password"
                    ></login-button>
                </v-form>
            </div>
            <div class="login__form-extra">
                <p class="ma-0">
                    {{ $t(`auth.login.registerTitle`) }}
                </p>
                <v-btn
                    text
                    depressed
                    color="primary"
                    :to="{name: 'Register'}"
                >
                    {{ $t("auth.login.registerButton") }}
                </v-btn>
            </div>
        </div>
  </v-container>
</template>

<script>
import GeneralInput from "@/components/General/FormInputs/GeneralInput.vue";
import PasswordInput from "@/components/General/FormInputs/PasswordInput.vue";
import LoginButton from "./components/LoginButton.vue";
export default {
    name:   "Login",

    components: {
        GeneralInput,
        PasswordInput,
        LoginButton
    },

    data() {
        return {
            username: "",
            password: "",
            passwordItem: {
                required: true,
                label: "password"
            },
            isUsernameValid: true,
            isPasswordValid: true,
        }
    },

    computed: {
        isValid() {
            if (!this.isUsernameValid && !this.isPasswordValid) {
                return false
            }
            return true
        }
    },
}
</script>

<style lang="scss" scoped>
 h1 {
    line-height: 1em;
}

h1 span {
    display: block;
    text-transform: initial;
}

span.subtitle {
    color: var(--v-black-base);
    display: block;
    font-size: 1.2rem;
}
.header {
    display: flex;
    align-items: center;
}

.logo img {
    height: 90px;
    object-fit: contain;
}
.login--wrapper-overlay {
    background-color: rgba(0,0,0, .6);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
}

.login-form-title {
    color: var(--v-primary-base);
}

.forgot-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.2em;
}
</style>