<template>
    <div>
        <v-dialog v-model="confirmDialog" persistent max-width="500">
            <v-card outlined>
                <v-card-title class="text-h5 d-flex flex-column">
                    <v-avatar
                        width="50"
                        height="50"
                        color="primary"
                        class="my-5"
                    >
                        <v-icon large color="white">
                            fas fa-radiation
                        </v-icon>
                    </v-avatar>
                    {{ $t(`base.confirmDialog.areYouSure`) }}
                </v-card-title>
                <v-card-text class="d-flex justify-center">
                    <slot name="content"></slot>
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="closeDialog()">
                        {{ $t(`base.confirmDialog.close`) }}
                    </v-btn>
                    <slot name="confirmButton"></slot>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

//
<script>
export default {
    name: "BaseConfirmDialog",

    props: {
        open: {
            required: true,
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            confirmDialog: false,
        };
    },

    methods: {
        closeDialog() {
            this.confirmDialog = false;
            this.$emit("update:open", false);
        },
    },

    watch: {
        confirmDialog(newValue) {
            if (newValue == false) {
                this.$emit("update:open", false);
            }
        },
        open(newValue) {
            if (newValue == true) {
                this.confirmDialog = newValue;
            }
            this.confirmDialog = newValue;
        },
    },
};
</script>

<style lang="scss" scoped></style>
