const defaultSideNav = [
    {
        to: "/landing",
        label: "home",
        icon: "fa-solid fa-house-chimney",
        active: false,
        hasSubItems: false,
    },
    {
        to: "",
        label: "tools",
        icon: "fa-solid fa-screwdriver-wrench",
        active: false,
        hasSubItems: true,
        subItems: [
            {
                to: "/document-validation",
                label: "ecidenceVerification",
                icon: "fa-solid fa-qrcode",
                canView: ["all"],
            },
            {
                to: "/eka-search-form",
                label: "searchWithEka",
                icon: "fa-solid fa-magnifying-glass",
                canView: ["all"],
            },
            {
                to: "/ota-statistics-search-form",
                label: "otaStatisticsSearch",
                icon: "fa-solid fa-chart-simple",
                canView: ["all"],
            },
        ],
    },
    {
        to: "/library-categories",
        label: "library",
        icon: "fa-solid fa-book",
        active: false,
        hasSubItems: false,
    },
];

const AdminSideNav = [
    {
        to: "/landing",
        label: "home",
        icon: "fa-solid fa-house-chimney",
        active: false,
        hasSubItems: false,
    },
    {
        to: "/companies",
        label: "organizations",
        icon: "fa-solid fa-building",
        active: false,
        hasSubItems: false,
    },
    {
        to: "/settlements",
        label: "facilities",
        icon: "fa-solid fa-industry",
        active: false,
        hasSubItems: false,
    },
    {
        to: "/collectors",
        label: "collectionTransport",
        icon: "fa-solid fa-truck",
        active: false,
        hasSubItems: false,
    },
    {
        to: "/ota",
        label: "municipalities",
        icon: "fa-solid fa-building-columns",
        active: false,
        hasSubItems: false,
    },
    {
        to: "/food-companies",
        label: "foodCompanies",
        icon: "fa-solid fa-building-wheat",
        active: false,
        hasSubItems: false,
    },
    {
        to: "",
        label: "management",
        icon: "fa-solid fa-gear",
        active: false,
        hasSubItems: true,
        subItems: [
            {
                to: "/settings",
                label: "settings",
                icon: "fa-solid fa-gears",
                active: false,
                hasInnerItems: false,
                canView: ["admin", "mod"],
            },
            {
                to: "/administrators",
                label: "administrators",
                icon: "fa-solid fa-users",
                active: false,
                hasInnerItems: false,
                canView: ["admin", "mod"],
            },
            {
                to: "/administrator-roles",
                label: "administratorRoles",
                icon: "fa-solid fa-users-gear",
                active: false,
                hasInnerItems: false,
                canView: ["admin", "mod"],
            },
            {
                to: "/send-mass-emails",
                label: "sendMashEmails",
                icon: "fa-solid fa-envelope",
                active: false,
                hasInnerItems: false,
                canView: ["admin", "mod"],
            },
            {
                to: "/send-mass-emails-with-link",
                label: "sendMashEmailsWithLink",
                icon: "fa-solid fa-envelope-open-text",
                active: false,
                hasInnerItems: false,
                canView: ["admin", "mod"],
            },
            {
                to: "/inactive-companies",
                label: "inActiveCompanies",
                icon: "fa-solid fa-building-circle-exclamation",
                hasInnerItems: false,
                canView: ["admin", "mod", "analyst"],
            },
            {
                to: "/deleted-companies",
                label: "deletedCompanies",
                icon: "fa-solid fa-building-circle-xmark",
                hasInnerItems: false,
                canView: ["admin", "mod", "analyst"],
            },
            {
                to: "/deactivated-companies",
                label: "deactivatedCompanies",
                icon: "fa-solid fa-building-flag",
                hasInnerItems: false,
                canView: ["admin", "mod", "analyst"],
            },
            {
                to: "/inactive-settlements",
                label: "inActiveFacilities",
                icon: "fa-solid fa-ban",
                active: false,
                hasInnerItems: false,
                canView: ["admin", "mod", "analyst"],
            },
            {
                to: "/deleted-settlements",
                label: "deletedFacilities",
                icon: "fa-solid fa-trash-can",
                active: false,
                hasInnerItems: false,
                canView: ["admin", "mod", "analyst"],
            },
            {
                to: "/deactivated-settlements",
                label: "deactivatedFacilities",
                icon: "fa-solid fa-circle-exclamation",
                active: false,
                hasInnerItems: false,
                canView: ["admin", "mod", "analyst"],
            },
            {
                to: "/files-list",
                label: "archiveList",
                icon: "fa-solid fa-rectangle-list",
                active: false,
                hasInnerItems: false,
                canView: ["admin", "mod", "analyst"],
            },
            {
                to: "",
                label: "predifinedLists",
                icon: "fa-solid fa-list-check",
                hasInnerItems: true,
                canView: ["admin", "mod"],
                subSubItems: [
                    {
                        to: "/ekacodes",
                        label: "ekaCode",
                        icon: "fa-solid fa-hashtag",
                    },
                    {
                        to: "/stacodes",
                        label: "nace2",
                        icon: "fa-solid fa-list-ol",
                    },
                    {
                        to: "/ya-members",
                        label: "oldMembers",
                        icon: "fa-solid fa-people-group",
                    },
                    {
                        to: "/seds",
                        label: "sed",
                        icon: "fa-solid fa-list-ul",
                    },
                ],
            },
            {
                to: "/e-paravolo",
                label: "eParavolo",
                icon: "fa-solid fa-receipt",
                active: false,
                hasInnerItems: false,
                canView: ["admin", "mod"],
            },
        ],
    },
    {
        to: "",
        label: "reports",
        icon: "fa-solid fa-folder-open",
        active: false,
        hasSubItems: true,
        subItems: [
            {
                to: "/waste-register-reports",
                label: "hmaReports",
                icon: "fa-solid fa-flag",
                canView: ["admin", "mod", "analyst"],
            },
            {
                to: "/food-waste-reports",
                label: "foodWasteReports",
                icon: "fa-solid fa-wheat-awn-circle-exclamation",
                canView: ["admin", "mod"],
            },
            {
                to: "/dashboard",
                label: "dashboard",
                icon: "fa-solid fa-gauge",
                canView: ["admin", "mod"],
            },
            {
                to: "/report-integrity",
                label: "qualityCheck",
                icon: "fa-solid fa-building-circle-check",
                canView: ["admin", "mod"],
            },
            {
                to: "/registry-search",
                label: "registryReports",
                icon: "fa-solid fa-file-lines",
                canView: ["admin", "mod"],
            },
            {
                to: "/aggregated-search",
                label: "wasteAggregateReports",
                icon: "fa-solid fa-boxes-packing",
                canView: ["admin", "mod"],
            },
            {
                to: "/business-relations",
                label: "professionalRelationships",
                icon: "fa-solid fa-people-arrows",
                canView: ["admin", "mod"],
            },
            {
                to: "/collect-transport",
                label: "collectTransport",
                icon: "fa-solid fa-truck",
                canView: ["admin", "mod"],
            },
            {
                to: "/unsubmitted-statements",
                label: "unsubmittedWasteReports",
                icon: "fa-solid fa-file-circle-exclamation",
                canView: ["admin", "mod"],
            },
            {
                to: "/zero-waste-statements",
                label: "zeroWasteReports",
                icon: "fa-solid fa-file-circle-minus",
                canView: ["admin", "mod"],
            },
        ],
    },
    {
        to: "",
        label: "tools",
        icon: "fa-solid fa-screwdriver-wrench",
        active: false,
        hasSubItems: true,
        subItems: [
            {
                to: "/document-validation",
                label: "ecidenceVerification",
                icon: "fa-solid fa-qrcode",
                canView: ["all"],
            },
            {
                to: "/serial-search-form",
                label: "searchRegistrationNumber",
                icon: "fa-solid fa-magnifying-glass",
                canView: ["all"],
            },
            {
                to: "/eka-search-form",
                label: "searchWithEka",
                icon: "fa-solid fa-magnifying-glass",
                canView: ["all"],
            },
            {
                to: "/ota-statistics-search-form",
                label: "otaStatisticsSearch",
                icon: "fa-solid fa-chart-simple",
                canView: ["all"],
            },
        ],
    },
    {
        to: "/library-categories",
        label: "library",
        icon: "fa-solid fa-book",
        active: false,
        hasSubItems: false,
    },
];

const companySideNav = [
    {
        to: "/landing",
        label: "home",
        icon: "fa-solid fa-house-chimney",
        active: false,
        hasSubItems: false,
    },
    {
        to: "/settlements",
        label: "myFacilities",
        icon: "fa-solid fa-building",
        active: false,
        hasSubItems: false,
    },
    {
        to: "/collectors",
        label: "myActivities",
        icon: "fa-solid fa-truck",
        active: false,
        hasSubItems: false,
    },
    {
        to: "",
        label: "reports",
        icon: "fa-solid fa-chart-column",
        active: false,
        hasSubItems: true,
        subItems: [
            {
                to: "/aggregated-search",
                label: "overallReports",
                icon: "fa-solid fa-box-archive",
                canView: ["company"],
            },
        ],
    },
    {
        to: "",
        label: "tools",
        icon: "fa-solid fa-screwdriver-wrench",
        active: false,
        hasSubItems: true,
        subItems: [
            {
                to: "/document-validation",
                label: "ecidenceVerification",
                icon: "fa-solid fa-qrcode",
                canView: ["all"],
            },
            {
                to: "/serial-search-form",
                label: "searchRegistrationNumber",
                icon: "fa-solid fa-magnifying-glass",
                canView: ["all"],
            },
            {
                to: "/eka-search-form",
                label: "searchWithEka",
                icon: "fa-solid fa-magnifying-glass",
                canView: ["all"],
            },
            {
                to: "/ota-statistics-search-form",
                label: "otaStatisticsSearch",
                icon: "fa-solid fa-chart-simple",
                canView: ["all"],
            },
        ],
    },
    {
        to: "/library-categories",
        label: "library",
        icon: "fa-solid fa-book",
        active: false,
        hasSubItems: false,
    },
];

const companyAndFoodSideNav = [
    {
        to: "/landing",
        label: "home",
        icon: "fa-solid fa-house-chimney",
        active: false,
        hasSubItems: false,
    },
    {
        to: "/settlements",
        label: "myFacilities",
        icon: "fa-solid fa-building",
        active: false,
        hasSubItems: false,
    },

    {
        to: "/collectors",
        label: "myActivities",
        icon: "fa-solid fa-truck",
        active: false,
        hasSubItems: false,
    },
    {
        to: "/food-waste-statements",
        label: "FoodWasteStatements",
        icon: "fa-solid fa-folder-open",
        active: false,
        hasSubItems: false,
    },
    {
        to: "",
        label: "reports",
        icon: "fa-solid fa-chart-column",
        active: false,
        hasSubItems: true,
        subItems: [
            {
                to: "/aggregated-search",
                label: "overallReports",
                icon: "fa-solid fa-box-archive",
                canView: ["company"],
            },
        ],
    },
    {
        to: "",
        label: "tools",
        icon: "fa-solid fa-screwdriver-wrench",
        active: false,
        hasSubItems: true,
        subItems: [
            {
                to: "/document-validation",
                label: "ecidenceVerification",
                icon: "fa-solid fa-qrcode",
                canView: ["all"],
            },
            {
                to: "/serial-search-form",
                label: "searchRegistrationNumber",
                icon: "fa-solid fa-magnifying-glass",
                canView: ["all"],
            },
            {
                to: "/eka-search-form",
                label: "searchWithEka",
                icon: "fa-solid fa-magnifying-glass",
                canView: ["all"],
            },
            {
                to: "/ota-statistics-search-form",
                label: "otaStatisticsSearch",
                icon: "fa-solid fa-chart-simple",
                canView: ["all"],
            },
        ],
    },
    {
        to: "/library-categories",
        label: "library",
        icon: "fa-solid fa-book",
        active: false,
        hasSubItems: false,
    },
];

const foodCompanySideNav = [
    {
        to: "/landing",
        label: "home",
        icon: "fa-solid fa-house-chimney",
        active: false,
        hasSubItems: false,
    },
    {
        to: "/food-waste-statements",
        label: "FoodWasteStatements",
        icon: "fa-solid fa-folder-open",
        active: false,
        hasSubItems: false,
    },
    {
        to: "",
        label: "tools",
        icon: "fa-solid fa-screwdriver-wrench",
        active: false,
        hasSubItems: true,
        subItems: [
            {
                to: "/document-validation",
                label: "ecidenceVerification",
                icon: "fa-solid fa-qrcode",
                canView: ["all"],
            },
            {
                to: "/serial-search-form",
                label: "searchRegistrationNumber",
                icon: "fa-solid fa-magnifying-glass",
                canView: ["all"],
            },
            {
                to: "/eka-search-form",
                label: "searchWithEka",
                icon: "fa-solid fa-magnifying-glass",
                canView: ["all"],
            },
            {
                to: "/ota-statistics-search-form",
                label: "otaStatisticsSearch",
                icon: "fa-solid fa-chart-simple",
                canView: ["all"],
            },
        ],
    },
    {
        to: "/library-categories",
        label: "library",
        icon: "fa-solid fa-book",
        active: false,
        hasSubItems: false,
    },
];

const settlementSideNav = [
    {
        to: "/landing",
        label: "home",
        icon: "fa-solid fa-house-chimney",
        active: false,
        hasSubItems: false,
    },
    {
        to: "/waste-report",
        label: "myStatements",
        icon: "fa-solid fa-folder-open",
        active: false,
        hasSubItems: false,
    },
    {
        to: "/my-partners",
        label: "collaborators",
        icon: "fa-solid fa-people-group",
        active: false,
        hasSubItems: false,
    },
    {
        to: "",
        label: "tools",
        icon: "fa-solid fa-screwdriver-wrench",
        active: false,
        hasSubItems: true,
        subItems: [
            {
                to: "/document-validation",
                label: "ecidenceVerification",
                icon: "fa-solid fa-qrcode",
                canView: ["all"],
            },
            {
                to: "/serial-search-form",
                label: "searchRegistrationNumber",
                icon: "fa-solid fa-magnifying-glass",
                canView: ["all"],
            },
            {
                to: "/eka-search-form",
                label: "searchWithEka",
                icon: "fa-solid fa-magnifying-glass",
                canView: ["all"],
            },
            {
                to: "/ota-statistics-search-form",
                label: "otaStatisticsSearch",
                icon: "fa-solid fa-chart-simple",
                canView: ["all"],
            },
        ],
    },
    {
        to: "/library-categories",
        label: "library",
        icon: "fa-solid fa-book",
        active: false,
        hasSubItems: false,
    },
];

export const getSideNavItems = (role, info) => {
    switch (role) {
        case "admin":
        case "mod":
        case "analyst":
            return AdminSideNav;
        case "company":
            if (info.is_food_company && !info.is_company) {
                return foodCompanySideNav;
            } else if (info.is_food_company && info.is_company) {
                return companyAndFoodSideNav;
            } else {
                return companySideNav;
            }
        case "settlement":
        case "collector":
            return settlementSideNav;
        default:
            return defaultSideNav;
    }
};
